import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { incrementLineItem, decrementLineItem, changeQuantity } from "./store/actions";

import "./Incrementor.css";


const Incrementor = (outerProduct) => {
    const dispatch = useDispatch();
    
    const cart = useSelector((state) => state.cart);

    const isMobile = useSelector((state) => state.device.isMobile);
    
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
       if (cart.loading === false) {
           const lineItem = cart.lineItems.find((item) => item.price.id === outerProduct.product.price.id);
           if (lineItem) {
               setQuantity(lineItem.quantity);
           }
       }
    }, [cart, outerProduct]);
    
    const handleIncrement = (product) => {
        dispatch(incrementLineItem(product));
    };
    
    const handleDecrement = (product) => {
        dispatch(decrementLineItem(product));
    };

    const handleQuantityChange = (product, quantity) => {
      dispatch(changeQuantity({ product, quantity: parseInt(quantity, 10)}));
      setQuantity(quantity);
    };
    
    return (
        <div>
        <div className="quantity-controls-container">
            <button
              className="decrement-button"
              onClick={(event) => {
                event.stopPropagation();
                handleDecrement(outerProduct.product)}
              }
            >-</button>
            {isMobile ?
              <input
                className="quantity-selector"
                value={quantity ? quantity : "0"}
                onChange={(event) => {
                  event.stopPropagation();
                  handleQuantityChange(outerProduct.product, event.target.value)}
                }
                onClick={(event) => event.stopPropagation()}>
              </input>
                : <select
                className="quantity-selector"
                value={quantity}
                onChange={(event) => {
                  event.stopPropagation();
                  handleQuantityChange(outerProduct.product, event.target.value)}
                }
                onClick={(event) => event.stopPropagation()}
                >
                {[...Array(21).keys()].map(num => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>}
            <button
              className="increment-button"
              onClick={(event) => {
                event.stopPropagation();
                handleIncrement(outerProduct.product)}}
            >+</button>
          </div>
        </div>
    );
    }

export default Incrementor;