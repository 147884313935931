import React, {   
 } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import { Provider } from 'react-redux';

import store from './store/store';

import Home from "./Home";
import CheckoutForm from "./CheckoutForm";
import Return from "./Return";


const App = () => {

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/return" element={<Return />} />
          </Routes>
        </Router>
      </Provider>
    </div>
  )
}

export default App;