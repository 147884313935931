// reducer.js
import { 
  ADD_LINE_ITEM,
  REMOVE_LINE_ITEM,
  INCREMENT_LINE_ITEM,
  DECREMENT_LINE_ITEM,
  CHANGE_QUANTITY,
  SET_CART_NOT_EMPTY_TRUE,
  SET_CART_NOT_EMPTY_FALSE,
  SET_CART_SESSION_INFO,
  CREATE_CHECKOUT_REQUEST,
  CREATE_CHECKOUT_SUCCESS,
  CREATE_CHECKOUT_FAILURE,
  INCREMENT_CHECKOUT_REQUEST_UPDATES,
  } from '../actions';

const initialState = {
  lineItems: [],
  loading: false,
  error: null,
  cartNotEmpty: false,
  checkoutRequested: false,
  checkoutRequestUpdates: 0,
  checkoutLoading: false,
  cartSessionId: null,
  clientSecret: null,
};


const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LINE_ITEM:
      return {
        ...state,
        lineItems: [...state.lineItems, {
            price: { id: action.payload.price.id },
            name: action.payload.name, quantity: 1 }],
      };
    case REMOVE_LINE_ITEM:
      return {
        ...state,
        lineItems: state.lineItems.filter(item => item.price.id !== action.payload),
      };
    case INCREMENT_LINE_ITEM:
      if (state.lineItems.find(item => item.price.id === action.payload.price.id)) {
        return {
          ...state,
          lineItems: state.lineItems.map(item =>
            item.price.id === action.payload.price.id ? { ...item, quantity: item.quantity + 1 } : item
          ),
        };
      } else {
        return {
          ...state,
          lineItems: [...state.lineItems, {
            price: { id: action.payload.price.id },
            name: action.payload.name, quantity: 1 }],
        };
      }
    case DECREMENT_LINE_ITEM:
      return {
        ...state,
        lineItems: state.lineItems.map(item =>
          item.price.id === action.payload.price.id && item.quantity > 0 ? { ...item, quantity: item.quantity - 1 } : item
        ),
      };
    case CHANGE_QUANTITY:
      if (state.lineItems.find(item => item.price.id === action.payload.product.price.id)) {
        return {
          ...state,
          lineItems: state.lineItems.map(item =>
            item.price.id === action.payload.product.price.id ?
              { ...item, quantity: action.payload.quantity } : item
          ),
        };
      } else {
        return {
          ...state,
          lineItems: [...state.lineItems, {
            price: { id: action.payload.product.price.id },
            name: action.payload.product.name, quantity: action.payload.quantity }],
        };
      }
    case SET_CART_NOT_EMPTY_TRUE:
      return {
        ...state,
        cartNotEmpty: true,
      };
    case SET_CART_NOT_EMPTY_FALSE:
      return {
        ...state,
        cartNotEmpty: false,
      };
    case CREATE_CHECKOUT_REQUEST:
      return {
        ...state,
        checkoutRequested: true,
        checkoutLoading: true,
      };
    case CREATE_CHECKOUT_SUCCESS:
      return {
        ...state,
        cartSessionId: action.payload.sessionId,
        clientSecret: action.payload.clientSecret,
        checkoutLoading: false,
      }
    case CREATE_CHECKOUT_FAILURE:
      return {
        ...state,
        checkoutLoading: false,
        error: action.error,
      };
    case SET_CART_SESSION_INFO:
      return {
        ...state,
        cartSessionId: action.payload.sessionId,
        clientSecret: action.payload.clientSecret,
      };
    case INCREMENT_CHECKOUT_REQUEST_UPDATES:
      return {
        ...state,
        checkoutRequestUpdates: state.checkoutRequestUpdates + 1,
      };
    default:
      return state;
  }
};

export default cartReducer;