import React, {
    useEffect,
    useRef,
} from "react";

import { useDispatch, useSelector } from "react-redux";

import { setIsMobile } from "./store/actions";

import Poster from "./Poster";
import ProductSelector from "./ProductSelector";
import CheckoutForm from "./CheckoutForm";


const Home = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
        dispatch(setIsMobile());
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        // Initial check
        handleResize();

        return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
        };
    }, [dispatch]);

    const checkoutRequested = useSelector((state) => state.cart.checkoutRequested);

    const checkoutRef = useRef(null);

    return (
        <div>
            <Poster />
            <ProductSelector />
            { checkoutRequested && 
                <CheckoutForm
                    checkoutRef={checkoutRef}
                 /> }
        </div>
    );
}

export default Home;