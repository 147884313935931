// rootReducer.js
import { combineReducers } from 'redux';

import cartReducer from './cartReducer';
import productsReducer from './productsReducer';
import deviceReducer from './deviceReducer';



const rootReducer = combineReducers({
  cart: cartReducer,
  products: productsReducer,
  device: deviceReducer,
  // Add other reducers here
});

export default rootReducer;

