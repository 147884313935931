// src/store/reducers/deviceReducer.js
import { SET_IS_MOBILE } from '../actions';

function checkIsMobile() {
    console.log('checkIsMobile');
  const isMobileDevice = /Mobi|Android/i.test(window.navigator.userAgent);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const isMobileScreen = window.innerWidth <= 960;

  console.log('isMobileDevice', (isMobileDevice && isPortrait) || isMobileScreen);
  return (isMobileDevice && isPortrait) || isMobileScreen;
}

const initialState = {
  isMobile: checkIsMobile(),
};

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: checkIsMobile(),
      };
    default:
      return state;
  }
};

export default deviceReducer;

