// productsReducer.js
import { 
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
    EXPAND_FEATURES,
    CLOSE_FEATURES,
 } from '../actions';

const initialState = {
  products: [],
  loading: false,
  error: null,
  expanded: false,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EXPAND_FEATURES:
      return {
        ...state,
        expanded: true,
      };
    case CLOSE_FEATURES:
      return {
        ...state,
        expanded: false,
      };
    default:
      return state;
  }
};

export default productsReducer;