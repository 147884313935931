import React,
{
    useRef,
} from 'react';

import { 
    useDispatch,
    useSelector
   } from 'react-redux';
  

import {
    expandFeatures,
    addLineItem,
} from './store/actions';

import Incrementor from './Incrementor';

import './Product.css'

// accept product as a prop
const Product = ({ product, index }) => {

    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);

    const expanded = useSelector((state) => state.products.expanded);

    const featuresRef = useRef(null);

    const priceFormatter = (price) => {
        return `${(price / 100).toFixed(2)}`;
      }

    const handleScrolltoClick = () => {
    if (featuresRef.current) {
        setTimeout(() => {
        featuresRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 200);
    }
    };

    function productTileClass() {
        let productTileClass = "product-tile";
        if (expanded || cart.cartNotEmpty) {
             productTileClass += " product-tile-expanded";
        }
        if (index !== 0) {
            productTileClass += " product-tile-not-first";
        }
        return productTileClass;
    }

return (
    <div>
        <div 
          className={productTileClass()}
          key={product.id}
          onClick={(event) => {
            event.stopPropagation();
            dispatch(expandFeatures());
            handleScrolltoClick();
          }}
          >
            <div className="product-name">{product.name}</div>
            <img className="product-images" src={product.images && product.images[0]} alt={product.name} />
            <div className="product-price">${priceFormatter(product.price.unit_amount)}</div>
            <div className="reserve-or-increment-container">
          {cart.lineItems.length === 0 ?
          <div 
          >
            <button
              className="reserve-button"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(addLineItem({ price: { id: product.price.id }}))
              }}
              >
                Reserve
              </button>
          </div> :
          <Incrementor product={product} />
            }
            </div>
                <div
                ref={featuresRef}
                className={"product-marketing-features-container" + ((expanded || cart.cartNotEmpty) ? " product-marketing-features-container-expanded" : "")}
                >
                    <ul>
                        {product.marketing_features.map((feature, index) => (
                        <li key={index}>{feature.name}</li>
                    ))}
                    </ul>
              </div>
            
        </div>
    </div>
    )
}

export default Product;
    

