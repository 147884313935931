import React, { 
  useEffect,
  useRef,
} from "react";

import { 
  useDispatch,
  useSelector
} from "react-redux";

import {
createCheckoutSession,
} from "./store/actions";

import {loadStripe} from '@stripe/stripe-js';

import {
EmbeddedCheckoutProvider,
EmbeddedCheckout,
} from '@stripe/react-stripe-js';

import './CheckoutForm.css'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  
  const clientSecret = useSelector((state) => state.cart.clientSecret);

  const isMobile = useSelector((state) => state.device.isMobile);

  const checkoutScrollRef = useRef(null);

  useEffect(() => {
    if (!cart.cartSessionId && !cart.checkoutLoading) {
      dispatch(createCheckoutSession());
    }
  }, [cart.cartSessionId, cart.checkoutLoading, dispatch]);

  useEffect(() => {
    const cartCount = cart.lineItems.filter((item) => item.quantity > 0).length;
    if (cart.cartSessionId && cartCount > 0) {
      dispatch(createCheckoutSession());
    }
  }, [cart.lineItems, dispatch]);


  const scrollCheckoutIntoView = () => {
    if (cart.checkoutRequestUpdates > 1) {
      return;
    }
    if (checkoutScrollRef.current) {
      setTimeout(() => {
        checkoutScrollRef.current.scrollIntoView({ behavior: "smooth" });
      }, 400);
    }
  }

  const checkoutFrameClass = () => {
    let className = "checkout-frame";
    if (isMobile) {
      className += " checkout-frame-mobile";
    }
    return className;
  }

  const options = { clientSecret };

  return (
    <div>
    <div 
      className={checkoutFrameClass()}
      >
        <EmbeddedCheckoutProvider 
          stripe={stripePromise} 
          options={options}
          key={clientSecret}
          >
          <EmbeddedCheckout
          onReady={scrollCheckoutIntoView()}
           />
        </EmbeddedCheckoutProvider>
        <div id="scroll-to"
          ref={checkoutScrollRef}
        />
    </div>
  </div>
  );
};


export default CheckoutForm;