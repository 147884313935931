// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { fetchProducts } from './actions';

const store = configureStore({
  reducer: rootReducer,
});

// Dispatch fetchProducts action to initialize state
store.dispatch(fetchProducts());

export default store;