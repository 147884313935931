// ProductSelector.js
import React, { 
  useEffect,
  useRef,
 } from 'react';

import { 
  useDispatch,
  useSelector
 } from 'react-redux';

import { 
  fetchProducts,
  createCheckoutSession,
  setCartNotEmptyTrue,
  closeFeatures,
 } from './store/actions';

import Product from './Product';

import ProductMobile from './ProductMobile';


import './ProductSelector.css';

const ProductSelector = () => {
  
  const dispatch = useDispatch();
  
  const {
    products,
    loading,
    error 
   } = useSelector(state => state.products);

  const cart = useSelector((state) => state.cart);
  
  const { isMobile } = useSelector(state => state.device);

  const checkoutRef = useRef(null);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (!cart.cartNotEmpty && (cart.lineItems.length > 0)) {
      dispatch(setCartNotEmptyTrue());
    }
  }, [cart.lineItems, cart.cartNotEmpty, dispatch])


  useEffect(() => {
    if (cart.cartNotEmpty) {
      setTimeout(() => {
        checkoutRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, [cart.cartNotEmpty]);

  function handleCheckoutRequest() {
    dispatch(createCheckoutSession());
    if (isMobile) {
      dispatch(closeFeatures());
    }
  }
  
  function productContainerClass() {
    let productContainerClass = "product-container";
    if (cart.cartNotEmpty) {
      productContainerClass += " product-container-checkout";
    }
    if (isMobile) {
      productContainerClass += " product-container-mobile";
    }
    return productContainerClass;
  }

  function checkoutButtonClass()  {
    let checkoutButtonClass = "checkout-button";
    if (isMobile) {
      checkoutButtonClass += "-mobile";
      if (cart.checkoutRequested && !cart.checkoutLoading) {
        checkoutButtonClass += " checkout-button-mobile-checkout-requested";
      }
    }
    if (cart.cartNotEmpty) {
      checkoutButtonClass += " checkout-button-visible";
    }
    if (cart.checkoutLoading) {
      checkoutButtonClass += " checkout-button-checkout-loading";
    }
    return checkoutButtonClass;
  }
  
  // TODO: add skeleton loading
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="parent-product-container">
        <div
        className={productContainerClass()}>
          {products.map((product, index) => (
            isMobile ? <ProductMobile key={product.id} product={product} index={index} />
            : <Product key={product.id} product={product} index={index}/>
          ))}
        </div>
      </div>
        <div 
          className={checkoutButtonClass()}
          ref={checkoutRef} 
          onClick={(event) => {
            event.stopPropagation();
            handleCheckoutRequest();
          }}>
            Checkout
          </div>
    </div>
  );
};

export default ProductSelector;