import React,
{
    useRef,
} from 'react';

import { 
    useDispatch,
    useSelector
   } from 'react-redux';
  

import {
    expandFeatures,
    addLineItem,
} from './store/actions';

import Incrementor from './Incrementor';

import './ProductMobile.css'

// accept product as a prop
const ProductMobile = ({ product, index }) => {

    const dispatch = useDispatch();

    const { isMobile } = useSelector(state => state.device);

    const cart = useSelector((state) => state.cart);

    const expanded = useSelector((state) => state.products.expanded);

    const featuresRef = useRef(null);

    const priceFormatter = (price) => {
        return `${(price / 100).toFixed(2)}`;
      }

    const handleScrolltoClick = () => {
    if (featuresRef.current) {
        setTimeout(() => {
          featuresRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 200);
    }
    };

    function productTileClass() {
      let productTileClass = "product-mobile-tile";
      if (expanded) {
           productTileClass += " product-mobile-tile-expanded";
      }
      if (index !== 0) {
          productTileClass += " product-mobile-tile-not-first";
      }
      return productTileClass;
  }

  function handleFeatureExpansion() {
    dispatch(expandFeatures());
    if (!isMobile) {
      handleScrolltoClick();
    }
  }
    

return (
    <div>
        <div 
          className={productTileClass()}
          key={product.id}
          onClick={(event) => {
            event.stopPropagation();
            handleFeatureExpansion();
          }}
          >
            <div className="product-mobile-name">{product.name}</div>
            <img className="product-mobile-image" src={product.images && product.images[0]} alt={product.name} />
            <div className="product-mobile-price">${priceFormatter(product.price.unit_amount)}</div>
            <div className="product-mobile-reserve-or-increment-container">
          {cart.lineItems.length === 0 ?
          <div 
          >
            <button
              className="mobile-reserve-button"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(addLineItem({ price: { id: product.price.id }}))
              }}
              >
                Reserve
              </button>
          </div> :
          <Incrementor product={product} />
            }
            </div>
                <div
                ref={featuresRef}
                className={"product-mobile-marketing-features-container" + ((expanded) ? " product-mobile-marketing-features-container-expanded" : "")}
                >
                    <ul>
                        {product.marketing_features.map((feature, index) => (
                        <li key={index}>{feature.name}</li>
                    ))}
                    </ul>
              </div>
            
        </div>
    </div>
    )
}

export default ProductMobile;
    

