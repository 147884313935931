// actions.js
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const EXPAND_FEATURES = 'EXPAND_FEATURES';
export const CLOSE_FEATURES = 'CLOSE_FEATURES';

export const ADD_LINE_ITEM = 'ADD_LINE_ITEM';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const INCREMENT_LINE_ITEM = 'INCREMENT_LINE_ITEM';
export const DECREMENT_LINE_ITEM = 'DECREMENT_LINE_ITEM';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';

export const SET_CART_NOT_EMPTY_TRUE = 'SET_CART_NOT_EMPTY_TRUE';
export const SET_CART_NOT_EMPTY_FALSE = 'SET_CART_NOT_EMPTY_FALSE';

export const SET_CART_SESSION_INFO = 'SET_CART_SESSION_INFO';

export const CREATE_CHECKOUT_REQUEST = 'CREATE_CHECKOUT_REQUEST';
export const CREATE_CHECKOUT_SUCCESS = 'CREATE_CHECKOUT_SUCCESS';
export const CREATE_CHECKOUT_FAILURE = 'CREATE_CHECKOUT_FAILURE';

export const INCREMENT_CHECKOUT_REQUEST_UPDATES = 'INCREMENT_CHECKOUT_REQUEST_UPDATES';

export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    try {
      // const response = await fetch('http://localhost:4242/get-products', {
      const response = await fetch('https://te4fnc2dn6.execute-api.us-east-1.amazonaws.com/products', {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();

      dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: data });
    } catch (error) {
      console.error('Fetch error:', error);
      dispatch({ type: FETCH_PRODUCTS_FAILURE, error: error.message });
    }
  };
};

export const createCheckoutSession = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_CHECKOUT_REQUEST });
    try {
      const state = getState();
      const cart = state.cart;
      const isMobile = state.device.isMobile;

      if (!Array.isArray(cart?.lineItems) || cart?.lineItems.length === 0) {
        throw new Error("lineItems is not an array or is empty");
      }

      function createBody() {
        if (isMobile) {
          const body = {
            function: "create-cart",
            message: "It is sending.",
            line_items: cart.lineItems.filter((item) => item.quantity > 0).map((item) => ({
              price: item.price.id,
              quantity: item.quantity,
              adjustable_quantity: {
                enabled: true,
                maximum: 100,
                minimum: 0,
              },
            })),
          };
          return body;
        }
        else {
          const body = {
            function: "create-cart",
            message: "It is sending.",
            line_items: cart.lineItems.filter((item) => item.quantity > 0).map((item) => ({
              price: item.price.id,
              quantity: item.quantity,
            })),
          };
          return body;
        }
      }

        

      const response = await fetch("https://te4fnc2dn6.execute-api.us-east-1.amazonaws.com/cart-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(createBody()),        
      });

      if (!response.ok) {
        console.log(response);
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      dispatch({ type: CREATE_CHECKOUT_SUCCESS, payload: data });
      dispatch(incrementCheckoutRequestUpdates());
    } catch (error) {
      console.error('Create checkout error:', error);
      dispatch({ type: CREATE_CHECKOUT_FAILURE, error: error.message });
    }
  };
};

export const expandFeatures = () => ({
  type: EXPAND_FEATURES,
});

export const closeFeatures = () => ({
  type: CLOSE_FEATURES,
});

export const updateLineItems = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const cart = state.cart;

    if (!cart.clientSecret) {
      return;
    }
    try {
      const response = await fetch("https://te4fnc2dn6.execute-api.us-east-1.amazonaws.com/cart-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the appropriate Content-Type
        },
        body: JSON.stringify({
          function: "update-cart",
          message: "It is updating.",
          cart_session_id: cart.cartSessionId,
          line_items: cart.lineItems.map((item) => ({
            price: item.price.id,
            quantity: item.quantity,
          })),
        }),
      });

      const data = await response.json();
      dispatch(setCartSessionInfo(data));
      dispatch(incrementCheckoutRequestUpdates());
    } catch (error) {
      console.error("Error updating line items:", error);
      throw error;
    }
  };
};


export const addLineItem = (item) => ({
  type: ADD_LINE_ITEM,
  payload: item,
});

export const removeLineItem = (id) => ({
  type: REMOVE_LINE_ITEM,
  payload: id,
});

export const incrementLineItem = (id) => ({
  type: INCREMENT_LINE_ITEM,
  payload: id,
});

export const decrementLineItem = (id) => ({
  type: DECREMENT_LINE_ITEM,
  payload: id,
});

export const changeQuantity = (item) => ({
  type: CHANGE_QUANTITY,
  payload: item,
});

export const setCartNotEmptyTrue = () => ({
  type: SET_CART_NOT_EMPTY_TRUE,
});

export const setCartNotEmptyFalse = () => ({
  type: SET_CART_NOT_EMPTY_FALSE,
});

export const setCartSessionInfo = (data) => ({
  type: SET_CART_SESSION_INFO,
  payload: data,
});

export const createCheckoutRequest = () => ({
  type: CREATE_CHECKOUT_REQUEST
})

export const incrementCheckoutRequestUpdates = () => ({
  type: INCREMENT_CHECKOUT_REQUEST_UPDATES
})

export const setIsMobile = () => ({
  type: SET_IS_MOBILE,
});